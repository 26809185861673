import React from 'react';
import '../../App.css';
import './AboutUs.css';
import Footer from '../Footer';
import harjeetImg from './harjeet.png';
import adiImg from './adi.jpg';
import abhiImg from './abhi.png';
import amishaImg from './amisha.png';
import julianImg from './julian.png';
import ishaanImg from './ishaan.png';
import diyaImg from './diya.png';
import anviImg from './anvi2.png';
import jeffImg from './jeff.png';
import enjunImg from './enjun.png';

export default function AboutUs() {
  return (
    <div className='total'>
      <h1 className='about-us'>ABOUT US</h1>

      {/* Biography Section 1 */}

      <div className="biography-section">
        <div className="biography-image-container">
          <img className="biography-image" src={abhiImg} alt="Person 2" />
        </div>
        <div className="biography-details">
          <h2 className="biography-name">Abhinay Sambaraju</h2>
          <p className="biography-position">President</p>
          <p className="biography-text">
            Abhinay is a high school student who is going to major in engineering. He teaches middle school and high school math for DreamTeam, and a fun fact about him is he's a black belt!
          </p>
        </div>
      </div>

      <div className="biography-section">
        <div className="biography-image-container">
          <img className="biography-image" src={jeffImg} alt="Person 2" />
        </div>
        <div className="biography-details">
          <h2 className="biography-name">Jefferson Hoang</h2>
          <p className="biography-position">Vice President</p>
          <p className="biography-text">
            Jeff is a high school student who is going to major in business. He teaches elementary and middle school math as well as reading for DreamTeam. A fun fact about him is he is also a swim instructor!
          </p>
        </div>
      </div>

      <div className="biography-section">
        <div className="biography-image-container">
          <img className="biography-image" src={enjunImg} alt="Person 2" />
        </div>
        <div className="biography-details">
          <h2 className="biography-name">Enjun Zhang</h2>
          <p className="biography-position">Recruitment Director</p>
          <p className="biography-text">
            Enjun is a high school student who is going to major in business. He teaches middle school/early high school math and also Chinese (Mandarin) for DreamTeam. A fun fact about him is that he is ranked Mythic in Brawl Stars!
          </p>
        </div>
      </div>


      <div className="biography-section">
        <div className="biography-image-container">
          <img className="biography-image" src={adiImg} alt="Person 1" />
        </div>
        <div className="biography-details">
          <h2 className="biography-name">Aditya Vij</h2>
          <p className="biography-position">Founder</p>
          <p className="biography-text">
            Aditya is a high school student who plans to major in computer science. He teaches math and Java for DreamTeam, and a fun fact about him is he plays guitar!
          </p>
        </div>
      </div>

      {/* Biography Section 2 */}
      <div className="biography-section">
        <div className="biography-image-container">
          <img className="biography-image" src={diyaImg} alt="Person 2" />
        </div>
        <div className="biography-details">
          <h2 className="biography-name">Diya Gulati</h2>
          <p className="biography-position">Co-Founder</p>
          <p className="biography-text">
           Diya is a high school student who plans to major in biomedical engineering. She teaches science and math for DreamTeam and mentors middle schoolers needing guidance transitioning into high school. A fun fact about her is she loves to bake!
          </p>
        </div>
      </div>

            {/* Biography Section 2 */}
            <div className="biography-section">
        <div className="biography-image-container">
          <img className="biography-image" src={anviImg} alt="Person 2" />
        </div>
        <div className="biography-details">
          <h2 className="biography-name">Anvi Gulati</h2>
          <p className="biography-position">Co-Founder</p>
          <p className="biography-text">
           Anvi attends American University and is majoring in finance. She teaches math and English for DreamTeam, and a fun fact about her is that she likes to paint!
          </p>
        </div>
      </div>

      <div className="biography-section">
        <div className="biography-image-container">
          <img className="biography-image" src={amishaImg} alt="Person 2" />
        </div>
        <div className="biography-details">
          <h2 className="biography-name">Amisha Vij</h2>
          <p className="biography-position">Co-Founder</p>
          <p className="biography-text">
           Amisha attends the University of Washington and is majoring in biology. She teaches biological sciences for DreamTeam, and a fun fact about her is her favorite TV show is New Girl !
          </p>
        </div>
      </div>

      <div className="biography-section">
        <div className="biography-image-container">
          <img className="biography-image" src={ishaanImg} alt="Person 2" />
        </div>
        <div className="biography-details">
          <h2 className="biography-name">Ishaan Musunuri</h2>
          <p className="biography-position">Co-Founder</p>
          <p className="biography-text">
           Ishaan is a high school student who is going to major in biology. He teaches biological sciences and math for DreamTeam, and a fun fact about him is he enjoys gardening!
          </p>
        </div>
      </div>

      <div className="biography-section">
        <div className="biography-image-container">
          <img className="biography-image" src={harjeetImg} alt="Person 2" />
        </div>
        <div className="biography-details">
          <h2 className="biography-name">Harjeet Singh</h2>
          <p className="biography-position">Co-Founder</p>
          <p className="biography-text">
           Harjeet is a high school student who is going to major in engineering. He teaches math and English for DreamTeam, and a fun fact about him is he plays tabla!
          </p>
        </div>
      </div>

      <div className="biography-section">
        <div className="biography-image-container">
          <img className="biography-image" src={julianImg} alt="Person 2" />
        </div>
        <div className="biography-details">
          <h2 className="biography-name">Julian Ng-Thow-Hing</h2>
          <p className="biography-position">Curriculum Director</p>
          <p className="biography-text2">
           Julian is a high school student who is going to major in computer science. He teaches math and physics for DreamTeam, and a fun fact about him is he goes on too many walks!
          </p>
        </div>
      </div>
      <Footer />
    </div>
  );
}
