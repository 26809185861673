import React from 'react';
import './Footer.css';
import { Button } from './Button';
import { Link } from 'react-router-dom';

function Footer() {
  return (
    <div className='footer-container'>
      <section className='footer-subscription'>
        <p className='footer-subscription-heading'>
          Explore more links below!
        </p>
        <div className='input-areas'>
        </div>
      </section>
      <div class='footer-links'>
        <div className='footer-link-wrapper'>
          <div class='footer-link-items'>
            <h2>About Us</h2>
            <Link to='/book-now'>How it works</Link>
            <Link to='/'>Testimonials</Link>
            <Link to='/'>Services</Link>
          </div>
          <div class='footer-link-items'>
            <h2>Contact Us</h2>
            <Link to='/book-now'>Contact</Link>
            <Link to='/book-now'>Support</Link>
            <Link to={{ pathname: "https://www.instagram.com/dreamteamtutorservices/?hl=en" }} target="_blank">Instagram</Link>
          </div>
        </div>
      </div>
      <section class='social-media'>
        <div class='social-media-wrap'>
          <small class='website-rights'>DREAMTEAM © 2023</small>
          <div class='social-icons'>
            <Link
              class='social-icon-link instagram'
              to={{ pathname: "https://www.instagram.com/dreamteamtutorservices/?hl=en" }}
              target='_blank'
              aria-label='Instagram'
            >
              <i class='fab fa-instagram' />
            </Link>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Footer;
