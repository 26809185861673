import React from 'react';
import './Testimonials.css';

const Testimonials = () => {
  return (
    <div className='testimonials-container'>
      <h2 className='testimonials-heading'>Testimonials</h2>
      <div className='testimonial left'>
        <p>
          "Thank you so much to DreamTeam for spending time with my son, they
          were very helpful, and he is doing better and better with math
          everyday. Looking forward to future sessions!"
        </p>
      </div>
      <div className='testimonial right'>
        <p>
          "I sat in for a tutoring session with DreamTeam, and the tutor was
          extremely polite and willing to help. He assigned homework and
          offered help outside of our sessions to make sure my child was
          learning at a fast rate. Now, my child is prepared for her upcoming
          school year!"
        </p>
      </div>
      <div className='testimonial left'>
        <p>
          "Diya was an extremely helpful tutor who spent tens of hours helping
          my son this summer. She was extremely on top of our meetings, and
          came to every meeting prepared. Huge thanks to DreamTeam!"
        </p>
      </div>
      <div className='testimonial right'>
        <p>
          "Adi is so helpful and so patient with my math preparation for 7th grade and he always asks if I need help and tries to clearly explain the answer if I get confused. Thanks!!"
        </p>
      </div>
    </div>
  );
};

export default Testimonials;
