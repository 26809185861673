import React from 'react';
import Navbar from './components/Navbar'
import { BrowserRouter  as Router, Switch, Route } from 'react-router-dom';
import './App.css';
import Home from './components/pages/Home';
import AboutUs from './components/pages/AboutUs';
import BookNow from './components/pages/BookNow';


function App() {
  return (
    <>
    <Router>
      <Navbar />
      <Switch>
        <Route path='/' exact component={Home} />
        <Route path='/about-us' exact component={AboutUs} />
        <Route path='/book-now' exact component={BookNow} />
      </Switch>
      </Router>
    </>
  );
}

export default App;