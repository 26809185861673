// BookNow.js
import React from 'react';
import '../../App.css';
import HeroSection from '../HeroSection';
import Footer from '../Footer';
import './BookNow.css'; // Import the CSS file

export default function BookNow() {
  const handleButtonClick = () => {
    window.open('https://ws3rzppl4hm.typeform.com/to/Bs2H0odY', '_blank');
  };

  return (
    <div className="book-now-container">
      <h1 className="book-now">BOOK NOW</h1>
      <h1 className="book-now-heading">Become a Student Below</h1>
      <div className="button-container">
        <button onClick={handleButtonClick} className="sign-up-button">
          Sign Up
        </button>
      </div>
      <h1 className="contact-heading">Contact Us:</h1>
      <div className="contact-info">
        <i className="far fa-envelope"></i>
        <h2 className="contact-text">dreamteamtutoringservice@gmail.com</h2>
      </div>
      <div className="contact-info">
        <i className="fab fa-instagram"></i>
        <h2 className="contact-text">@dreamteamtutorservices</h2>
      </div>
      <div className="contact-info">
        <i className="fas fa-phone"></i>
        <h2 className="contact-text">+1(408)-826-9296</h2>
      </div>
      <div className="contact-info sms-section"> {/* Apply the custom class to the SMS section */}
        <i className="fas fa-sms"></i>
        <h2 className="contact-text">+1(408)-826-9296</h2>
      </div>
      <Footer />
    </div>
  );
}
